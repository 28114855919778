<template>
  <div class="user-content">
    <div class="bold">
      <h2>个人信息</h2>
    </div>
    <div class="contain">
      <div class="sideBar">
        <ul>
          <li @click="selected = 0" :class="{ on: selected == 0 }">基本信息</li>
          <li @click="selected = 1" :class="{ on: selected == 1 }">账号与安全</li>
        </ul>
      </div>
      <el-scrollbar class="u-ST">
        <!-- 基本信息 -->
        <el-form ref="dataForm" :model="dataForm" :rules="dataRule" label-width="120px" label-position="left" class="orgApply-form" v-if="selected == 0">
          <el-form-item label="证件照" prop="headIcon" class="upload">
            <el-upload v-model="dataForm.headIcon" class="avatar-uploader" :action="uploadImg()" :headers="uploadHeaders()" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <img v-if="dataForm.headIcon" :src="imgUrl" class="avatar" />
              <img v-else src="../../assets/image/uoloadAvatar.png" alt="" class="uploadIcon"/>

              <!--              <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
              <div slot="tip" class="el-upload__tip">（只能上传jpg/png/jepg文件，且不超过10M）</div>
            </el-upload>
          </el-form-item>

          <el-form-item label="真实姓名" prop="realName">
            <el-input v-model="dataForm.realName" placeholder="请输入真实姓名"></el-input>
          </el-form-item>
          <el-form-item label="证件类型" prop="certificatesType">
            <el-select v-model="dataForm.certificatesType" placeholder="请选择证件类型">
              <el-option v-for="item in certificatesTypeList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="身份证" prop="certificatesNumber" v-if="dataForm.certificatesType === '居民身份证'">
            <el-input v-model="dataForm.certificatesNumber" placeholder="请输入身份证" @blur="getGenderBirth" maxlength="18"></el-input>
          </el-form-item>
          <el-form-item label="证件号" prop="certificatesNumber" v-else>
            <el-input v-model="dataForm.certificatesNumber" placeholder="请输入证件号"></el-input>
          </el-form-item>
          <el-form-item label="出生日期" prop="birthday">
            <el-col :span="11">
              <el-date-picker type="date" placeholder="选择出生日期" v-model="dataForm.birthday" style="width: 555px" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="昵称" prop="nickName">
            <el-input v-model="dataForm.nickName" placeholder="请输入昵称"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="gender">
            <el-radio-group v-model="dataForm.gender">
              <el-radio label="1">男</el-radio>
              <el-radio label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="dataForm.email" placeholder="请输入邮箱地址"></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="area">
            <el-cascader :options="options" v-model="selectedOptions" :placeholder=" dataForm.area == null ? '请选择所在区域' : dataForm.area" @change="handleChange"></el-cascader>
          </el-form-item>
          <el-form-item label="通讯地址" prop="postalAddress">
            <el-input type="textarea" v-model="dataForm.postalAddress" placeholder="请输入通讯地址" style="width: 555px;"></el-input>
          </el-form-item>
          <el-divider></el-divider>

          <el-form-item class="applyOrgBtn">
            <el-button type="primary" @click="handleConfirm">提交</el-button>
            <el-button @click="goBack">取消</el-button>
          </el-form-item>
        </el-form>

        <!-- 账号与安全 -->
        <el-form label-width="82px" label-position="left" class="security-form readonly" v-if="selected == 1">
          <el-form-item label="手机号" prop="phone ">
            <el-input v-model="oldPhoneForm.phone" readonly maxlength="11"></el-input>
            <el-button @click="showCheckPhone('phone')" class="btnbor">更改</el-button>
          </el-form-item>

          <el-form-item label="密码" prop="password">
            <el-input value="已设置" readonly></el-input>
            <el-button class="btnbor" @click="showCheckPhone('password')">更改</el-button>
          </el-form-item>
        </el-form>
      </el-scrollbar>
    </div>

    <!-- 身份验证（旧手机） -->
    <el-dialog :title="showTitle" :visible.sync="showOldPhonebar" class="securitybar">
      <!-- 身份验证 -->
      <el-form v-if="!showPhonebar && !showPasswordbar" ref="oldPhoneForm" :model="oldPhoneForm" class="security-form">
        <el-form-item prop="phone">
          <el-input v-model="oldPhoneForm.phone" readonly placeholder="手机号"></el-input>
        </el-form-item>

        <el-form-item prop="code" class="flex-code">
          <el-input v-model="oldPhoneForm.code" maxlength="6" placeholder="验证码"></el-input>
          <el-button class="btnr" @click="getCodes(0)" :disabled="disabledCodeBtn">{{ codeText }}</el-button>
        </el-form-item>
        <el-form-item class="dialog-footer">
          <el-button @click="showOldPhonebar = false">取 消</el-button>
          <el-button type="primary" @click="determinePhone('oldPhoneForm', getType)">确 定</el-button>
        </el-form-item>
      </el-form>

      <!-- 设置新手机号   :rules="phoneRules" -->
      <el-form v-if="showPhonebar" ref="phoneForm" :model="phoneForm" class="security-form">
        <el-form-item prop="phone ">
          <el-input v-model="phoneForm.phone" placeholder="手机号"></el-input>
        </el-form-item>

        <el-form-item prop="code" class="flex-code">
          <el-input v-model="phoneForm.code" maxlength="6" placeholder="验证码"></el-input>
          <el-button class="btnr" @click="getCodes(1)" :disabled="disabledCodeBtn">{{ codeText }}</el-button>
        </el-form-item>
        <el-form-item class="dialog-footer">
          <el-button @click="showOldPhonebar = false">取 消</el-button>
          <el-button type="primary" @click="determinePhone('phoneForm')">确 定</el-button>
        </el-form-item>
      </el-form>

      <!-- 设置新密码 -->
      <el-form v-if="showPasswordbar" ref="passForm" :model="passForm" :rules="passRules" class="security-form">
        <!-- <el-form-item prop="oldPassword">
          <el-input
            v-model="passForm.oldPassword"
            placeholder="旧密码"
          ></el-input>
        </el-form-item> -->

        <el-form-item prop="userPassword">
          <el-input v-model="passForm.userPassword" placeholder="新密码"></el-input>
        </el-form-item>
        <el-form-item prop="validatePassword">
          <el-input v-model="passForm.validatePassword" placeholder="再次输入新密码"></el-input>
        </el-form-item>
        <el-form-item class="dialog-footer">
          <el-button @click="showPasswordbar = false">取 消</el-button>
          <el-button type="primary" @click="ResetPasswords">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 成功与失败 -->
    <el-dialog :visible.sync="showOK" class="showmsg" :show-close="false">
      <div class="tips-cont" v-if="isok">
        <span class="iconfont icon-chenggong isok"></span>设置成功
      </div>
      <div class="tips-cont" v-else>
        <span class="iconfont isno icon-shibai"></span>设置失败
      </div>
    </el-dialog>
  </div>
</template>

<script>
import md5 from "js-md5";
import { regionData, CodeToText } from "element-china-area-data";
import {
  uploadAvatar,
  updateUser,
  getUserInfo,
  getCode,
  getCheckUserphone,
  ResetPassword,
} from "@/api/user";

export default {
  name: "user-container",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.passForm.validatePassword !== "") {
          this.$refs.passForm.validateField("validatePassword");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.passForm.userPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    return {
      options: regionData,
      selectedOptions: [],
      certificatesTypeList: ["居民身份证", "士官证", "学生证", "驾驶证", "护照", "港澳通行证",],
      selected: 0,
      dataForm: {
        headIcon: "",
        realName: "",
        certificatesType: "",
        certificatesNumber: "",
        birthday: "",
        nickName: "",
        mobilePhone: "",
        gender: "", //男1 女2
        email: "",
        area: null,
        postalAddress: "",
        id: "",
      },
      imgUrl: "",
      dataRule: {
        realName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        certificatesType: [
          { required: true, message: "请上选择证件类型", trigger: "blur" },
        ],
        certificatesNumber: [
          { required: true, message: "请输入证件号码", trigger: "blur" },
          // {
          //   pattern:
          //     /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X|x)$/,
          //   message: "请输入有效的身份证号",
          //   trigger: "blur",
          // },
          // {
          //   required: true,
          //   len: 18,
          //   message: "请正确输入身份证号码",
          //   trigger: "input",
          // },
        ],
        nickName: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        birthday: [
          { required: true, message: "请选择出生日期", trigger: "blur" },
        ],
        mobilePhone: [
          { required: true, message: "请填写联系电话", trigger: "input" },
          {required: true, len: 11, message: "请输入正确的联系方式", trigger: "blur",},
          {pattern: /^1[3456789]\d{9}$|^0\d{2,3}-?\d{7,8}$/, message: "请输入正确的联系方式", trigger: "blur",},
        ],
        gender: [{ required: true, message: "请选择性别", trigger: "input" }],
        email: [{ required: true, message: "请填写邮箱", trigger: "input" }],
        area: [{ required: true, message: "请选择地区", trigger: "blur" }],
        postalAddress: [
          { required: true, message: "请填写通讯地址", trigger: "input" },
        ],
      },
      showOldPhonebar: false,
      showPhonebar: false,
      showPasswordbar: false,
      showTitle: "身份验证",
      getType: "",
      codeText: "获取验证码",
      disabledCodeBtn: false,
      waitTime: 61,
      showOK: false,
      isok: true,

      oldPhoneForm: {phone: "", code: "", type: 0,},

      phoneForm: {phone: "", code: "", type: 1,},
      phoneRules: {
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },

      passForm: {/* oldPassword: "",*/ id: "", userPassword: "", validatePassword: "",},
      passRules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        userPassword: [
          { validator: validatePass, trigger: "blur", trigger: "blur" },
        ],
        validatePassword: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      getUserInfo().then((res) => {
        if (res.code === 200) {
          this.dataForm = res.data;
          this.imgUrl = this.loadUrl(res.data.headIcon);
          this.oldPhoneForm.phone = res.data.mobilePhone;
          this.dataForm.gender = JSON.stringify(res.data?.gender);
        }
      });
    },
    //提交表单
    handleConfirm() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          updateUser(this.dataForm).then((res) => {
            this.$message({message: res.msg, type: "success", duration: 1500,});
          });
        }
      });
    },

    //选择地区
    handleChange(value) {
      let self = this;
      let provinceCode = CodeToText[self.selectedOptions[0]].concat("/");
      let cityCode = CodeToText[self.selectedOptions[1]].concat("/");
      let countyCode = CodeToText[self.selectedOptions[2]];

      // CodeToText属性是区域码，属性值是汉字 CodeToText['110000']输出北京市
      self.dataForm.area = provinceCode.concat(cityCode, countyCode);
    },

    //根据身份证获取性别和生日
    getGenderBirth() {
      // console.log(this.dataForm.certificatesNumber);
      let birth = this.IdCard(this.dataForm.certificatesNumber, 1);
      this.dataForm.birthday = birth;
      this.dataForm.gender = this.IdCard(this.dataForm.certificatesNumber, 2);
      // console.log(this.dataForm.gender);
    },

    //上传头像
    handleAvatarSuccess(res, file) {
      this.dataForm.headIcon = res.data.url;
      this.imgUrl = this.loadUrl(res.data.url);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || "image/jpg" || "image/png";
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传头像图片格式错误!");
      }
      if (!isLt10M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isJPG && isLt10M;
    },
    goBack() {
      this.$refs["dataForm"].resetFields();
    },
    // 弹出验证身份
    showCheckPhone(type) {
      this.showOldPhonebar = true;
      this.showPhonebar = false;
      this.showPasswordbar = false;
      this.resetForm();
      this.getType = type;
    },
    determinePhone(formName, getType) {
      const self = this;
      if (formName === "oldPhoneForm" && self.oldPhoneForm.code == "") {
        self.$message("验证码不能为空!");
        return false;
      }

      if (formName === "phoneForm" && self.phoneForm.phone == "") {
        self.$message("手机号不能为空!");
        return false;
      } else if (formName === "phoneForm" && self.phoneForm.code == "") {
        self.$message("验证码不能为空!");
        return false;
      }

      self.$refs[formName].validate((valid) => {
        if (valid) {
          let params =
            formName === "oldPhoneForm" ? self.oldPhoneForm : self.phoneForm;

          getCheckUserphone(null, params).then((res) => {
            if (res.code == 200) {
              if (formName === "oldPhoneForm" && getType === "phone") {
                self.showPhonebar = true;
                self.showPasswordbar = false;
                self.showTitle = "设置新手机号";
              } else if (
                formName === "oldPhoneForm" &&
                getType === "password"
              ) {
                self.showPasswordbar = true;
                self.showPhonebar = false;
                self.showTitle = "设置新密码";
              } else {
                self.showOldPhonebar = false;
                self.showTitle = "身份验证";
              }
            }
            if (formName === "phoneForm") {
              self.showOK = true;
              self.isok = res.code == 200 ? true : false;
              setTimeout(() => {
                self.showOK = false;
              }, 3000);
            }

            self.oldPhoneForm.code = "";
            self.phoneForm.code = "";
            self.waitTime = 1;
            self.$message(res.msg);
          });
        }
      });
    },

    resetForm(formName) {
      this.showPasswordbar = false;
      this.showPhonebar = false;

      this.phoneForm.phone = "";
      this.phoneForm.type = "";
      this.phoneForm.code = "";

      this.passForm.userPassword = "";
      this.passForm.validatePassword = "";
      this.passForm.id = "";
    },
    // 确认设置密码
    ResetPasswords() {
      const self = this;
      self.$refs["passForm"].validate((valid) => {
        if (valid) {
          let userid = this.dataForm.id;
          this.passForm.id = userid;
          this.passForm.userPassword = md5(this.passForm.userPassword);
          this.passForm.validatePassword = md5(this.passForm.validatePassword);
          let params = this.passForm;
          ResetPassword(params, userid).then((res) => {
            if (res.code == 200) {
              self.showOldPhonebar = false;
              self.isok = true;
            } else {
              self.isok = false;
            }
            self.oldPhoneForm.code = "";
            self.phoneForm.code = "";
            self.showOK = true;
            setTimeout(() => {
              self.showOK = false;
            }, 3000);
            // self.$message(res.msg);
          });
        }
      });
    },

    // 验证码
    getCodes(type) {
      const self = this;
      let params = {
        account: type == 0 ? self.oldPhoneForm.phone : self.phoneForm.phone,
        type: 1,
      };
      if (params.account == "") {
        self.$message("手机号码不能为空!");
        return false;
      }
      getCode(params).then((res) => {
        if (res.code == 200) {
          self.getCodeTime();
          self.$message("验证码发到手机上");
        } else {
          self.$message(res.msg);
        }
      });
    },
    // 倒计时方法
    getCodeTime() {
      // 因为下面用到了定时器，需要保存this指向
      let self = this;
      self.waitTime--;
      self.disabledCodeBtn = true;
      self.codeText = `${self.waitTime}s 后重新获取`;
      let timer = setInterval(function () {
        if (self.waitTime > 1) {
          self.waitTime--;
          self.codeText = `${self.waitTime}s 后重新获取`;
        } else {
          clearInterval(timer);
          self.codeText = "获取验证码";
          self.disabledCodeBtn = false;
          self.waitTime = 61;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>

.user-content {
  color: #555555;
  .bold {
    height: 54px;
    h2 {
      height: 54px;
      line-height: 54px;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 30px;
      border-bottom: 1px solid #ececec;
    }
  }
  .contain {
    display: flex;
    margin-top: 22px;
    .sideBar {
      width: 160px;
      border-right: 1px solid #ececec;
      min-height: 90vh;
      ul {
        padding-top: 6px;
        li {
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          color: #555555;
          cursor: pointer;

          &.on {
            color: #1e50ae;
          }
        }
      }
    }

    .orgApply-form {
      padding-left: 20px;
      padding-top: 20px;
      font-size: 14px;
      color: #555555;

      .bold {
        h2 {
          font-size: 16px;
          font-weight: bold;
          padding-bottom: 12px;
          margin-bottom: 30px;
          border-bottom: 1px solid #ececec;
        }
      }

      ::v-deep .el-textarea__inner {
        font-family: Microsoft YaHei;
      }

      ::v-deep .el-form-item__label {
        font-size: 14px;
        color: #555555;
      }

      ::v-deep .el-input {
        width: 555px;
        color: #555555;
        font-family: Microsoft YaHei;
      }

      ::v-deep.avatar-uploader .el-upload {
        //border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 82px;
        height: 82px;
      }

      ::v-deep.avatar-uploader .el-upload:hover {
        border-color: #409eff;
      }

      ::v-deep.el-upload__tip {
        color: #999999;
      }
      .avatar {
        width: 82px;
        height: 82px;
        object-fit: cover;
        border-radius: 50%;
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 82px;
        height: 82px;
        //line-height: 360px;
        //text-align: center;
        fill: #c2c2c2;

        :hover {
          fill: #409eff;
        }
      }

      .avatar-uploader-icon :hover {
        fill: #409eff;
      }

      .large {
        width: 400px;
        object-fit: contain;
      }

      .applyOrgBtn {
        ::v-deep .el-button {
          width: 80px;
          height: 34px;
          line-height: 9px;
        }

        ::v-deep .el-form-item__content {
          margin-left: 0 !important;
          //margin-top: 22px;
        }
      }

      ::v-deep.el-upload__tip {
        margin-top: 0;
      }

      .el-checkbox-group {
        width: 580px;
      }

      .remark,
      .download {
        color: #409eff;
        cursor: pointer;
      }
    }

    .security-form {
      padding-left: 29px;
      font-size: 14px;
      color: #333;
      &.readonly {
        .el-input {
          width: 300px;
          /deep/ .el-input__inner {
            background: #f0f0f0;
            border: 1px solid #e4e4e4;
            border-radius: 4px;
            color: #999999;
          }
        }
        .btnbor {
          border: 0;
          color: #1e50ae;
          font-size: 14px;
          font-weight: 400;
          &:hover {
            color: #1e50ae;
            background: transparent;
          }
        }
      }
    }
  }
}

.securitybar {
  /deep/ .el-dialog {
    width: 518px;
    box-shadow: 0px 9px 40px 0px rgba(75, 75, 75, 0.12);
    border-radius: 10px;
  }
  /deep/ .el-dialog__headerbtn {
    font-size: 20px;
  }

  .security-form {
    /deep/ .el-input__inner {
      padding: 24px 10px;
    }
    .dialog-footer {
      padding-top: 22px;
      margin-bottom: 0;
      display: flex;
      justify-content: center;
    }
    .btnr {
      margin-left: 10px;
      line-height: 1.7;
    }
  }
  /deep/ .el-dialog__header {
    border-bottom: solid 1px #e5e5e5;
    padding-bottom: 20px;
  }

  /deep/ .el-form-item__content {
    padding: 0 10px;
    display: flex;
    align-items: center;
  }
}
.showmsg {
  .tips-cont {
    line-height: 35px;
    display: flex;
    align-self: center;
    justify-content: center;
    .iconfont {
      font-size: 35px;
      padding-right: 10px;
      &.isok {
        color: #5bc001;
      }
      &.isno {
        color: #ff3f35;
      }
    }
  }

  /deep/ .el-dialog {
    width: 250px;
    height: 100px;
    background: #ffffff;
    box-shadow: 0px 9px 40px 0px rgba(75, 75, 75, 0.12);
    border-radius: 10px;
  }

  /deep/ .el-dialog__header {
    display: none;
  }

  /deep/ .el-form-item__content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #333333;
  }
}
</style>

